<template>
    <div class="content">
        <BreadCrumbs :elements="[{ link: 'Blog', name: 'Blog' }]" />
        <section class="py-md-5" v-if="Object.keys(model).length > 0">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 offset-md-2">
                        <h1 class="title text-center mt-5">
                            {{ model.title }}
                        </h1>
                        <div class="text-small mb-4 text-center">
                            {{ model.publication_date }}
                        </div>
                        <img
                            :src="appUrl + model.main_image"
                            class="w-100 mb-5"
                        />

                        <article v-html="model.content"></article>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import BreadCrumbs from "@/components/BreadCrumbs";

export default {
    name: "BlogPost",
    components: { BreadCrumbs },
    data() {
        return {
            model: {}
        };
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_URL;
        },
        appUrl: function() {
            return process.env.VUE_APP_APP_URL;
        }
    },
    beforeMount() {
        this.getSearchData();
    },
    methods: {
        getSearchData() {
            ApiService.get(
                `${this.url}/blog/get/${this.$route.params.slug}`
            ).then(response => {
                this.model = response.data.data;
                this.metaCreation(this.model);
            });
        }
    }
};
</script>

<style scoped></style>
